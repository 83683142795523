import { Modal } from '@smartsheet/lodestar-core/';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { HttpStatusCodes } from '../../../../common/enums';
import { isEmpty } from '../../../../common/utils';
import { isAxiosErrorWithResponse } from '../../../../common/utils/isAxiosErrorWithResponse';
import ModalWrapper from '../../../../components/Modal';
import Spinner, { Color, Size } from '../../../../components/Spinner';
import conversationClient from '../../../../http-clients/ConversationClient';
import { useLanguageElements } from '../../../../language-elements/withLanguageElementsHOC';
import { StoreState } from '../../../../store';
import * as AppActions from '../../../App/Actions';
import { currentRowSheetIdSelector } from '../../Selectors';
import { Actions } from '../Actions';
import { UnsavedChangesModalContent } from '../DetailsData/UnsavedChangesModalContent';
import { LoadingData } from '../LoadingData';
import { selectDetailsIsDirty } from '../Selectors';
import Comment from './Comment';
import CommentInput from './CommentInput';
import { formatCurrentUser } from './ConversationUtils';
import { CommentType } from './types';

export interface ConversationProps {
    viewId: string;
    rowId: string;
    width: number;
    updateCommentsTotal: (total: number) => void;
    updateLoading: (loading: boolean) => void;
    addComments?: boolean;
    onChangeIsDirty: (isDirty: boolean) => void;
    showModal: boolean;
    activeTab: boolean;
}

enum ComponentState {
    LOADING,
    SAVING,
    IDLE,
}

export interface UnsavedComment {
    commentId: string;
    parentCommentId: string;
    text: string;
}

type UnsavedComments = UnsavedComment[];

const Conversation: React.FC<ConversationProps> = ({
    rowId,
    viewId,
    updateCommentsTotal,
    updateLoading,
    addComments = false,
    onChangeIsDirty,
    showModal,
    activeTab,
}) => {
    const [comments, setComments] = useState<CommentType[]>([]);
    const [unsavedReplyOrComment, setUnsavedReplyOrComment] = useState<UnsavedComments>([]);
    const [componentState, setComponentState] = useState<ComponentState>(ComponentState.IDLE);

    const languageElements = useLanguageElements();
    const dispatch = useDispatch();
    const user = useSelector((state: StoreState) => state.auth.user);
    const reportSheetId = useSelector(currentRowSheetIdSelector);

    const isDirty = useSelector(selectDetailsIsDirty);
    const setIsDirty = useCallback((target: boolean) => dispatch(Actions.setDetailsDirtyState(target)), [dispatch]);
    // format the current user object to match the User Service interface
    // can be removed after complete migration to User Service
    const formattedCurrentUser = formatCurrentUser(user);

    const fetchComments = useCallback(async () => {
        updateLoading(true);
        setIsDirty(false);
        onChangeIsDirty(false);
        setComponentState(ComponentState.LOADING);
        try {
            const response = await conversationClient.getRowConversationComments(viewId, rowId, reportSheetId);
            const sortedComments = response?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            setComments(sortedComments);
            updateCommentsTotal(sortedComments.length);
            setUnsavedReplyOrComment([]);
            setComponentState(ComponentState.IDLE);
        } catch (error) {
            if (
                isAxiosErrorWithResponse(error) &&
                (error.response?.status === HttpStatusCodes.FORBIDDEN || error.response?.status === HttpStatusCodes.NOT_FOUND)
            ) {
                // If a 403 (Forbidden) or 404 (Not found) error is returned, then the user no longer has access to the row. Ignore the error
                // because the data tab will throw the appropriate error message.
                return;
            }
            setComponentState(ComponentState.IDLE);
            dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.GENERIC_FATAL_ERROR)));
        } finally {
            updateLoading(false);
        }
    }, [dispatch, languageElements.GENERIC_FATAL_ERROR, reportSheetId, rowId, updateCommentsTotal, updateLoading, viewId]);

    useEffect(() => {
        setUnsavedReplyOrComment([]);
        fetchComments();
    }, [rowId, reportSheetId]);

    const handleCloseModal = () => dispatch(Actions.closeModalDetailsPanel());

    const handleChangeUnsavedReplyOrComment = (unsavedComment: UnsavedComment) => {
        const { commentId, parentCommentId, text } = unsavedComment;
        const trimmedText = text.trim();

        setUnsavedReplyOrComment((prev) => {
            const updatedState = [...prev];
            const commentIndex = updatedState.findIndex((comment) => comment.commentId === commentId && comment.parentCommentId === parentCommentId);

            if (trimmedText) {
                if (commentIndex > -1) {
                    updatedState[commentIndex].text = trimmedText;
                } else {
                    updatedState.push({ commentId, parentCommentId, text: trimmedText });
                }
            } else if (commentIndex > -1) {
                updatedState.splice(commentIndex, 1);
            }
            setIsDirty(updatedState.length > 0);
            onChangeIsDirty(updatedState.length > 0);
            return updatedState;
        });
    };

    const handleDiscardUnsavedChanges = () => {
        setComponentState(ComponentState.IDLE);
        setUnsavedReplyOrComment([]);
        handleCloseModal();
        fetchComments();
    };

    const handleSaveAllUnsavedChanges = async (): Promise<void> => {
        if (!isDirty) {
            handleCloseModal();
            return;
        }
        setComponentState(ComponentState.SAVING);
        try {
            await Promise.all(unsavedReplyOrComment.map(handleSave));
            handleDiscardUnsavedChanges();
        } catch {
            dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.GENERIC_FATAL_ERROR)));
        } finally {
            fetchComments();
        }
    };

    const handleSave = async (unsavedItem: UnsavedComment) => {
        const { text, commentId, parentCommentId } = unsavedItem;
        if (!text.trim()) {
            return;
        }
        setComponentState(ComponentState.SAVING);
        try {
            if (parentCommentId === '0' && commentId === '0') {
                await conversationClient.addConversationComment(viewId, rowId, text, undefined, reportSheetId);
            } else if (commentId === parentCommentId) {
                await conversationClient.updateConversationComment(viewId, rowId, text, parentCommentId, reportSheetId);
            } else if (commentId === '0') {
                await conversationClient.addConversationComment(viewId, rowId, text, parentCommentId, reportSheetId);
            } else {
                await conversationClient.updateConversationComment(viewId, rowId, text, commentId, reportSheetId);
            }
        } catch {
            setComponentState(ComponentState.IDLE);
            dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.GENERIC_FATAL_ERROR)));
        } finally {
            setComponentState(ComponentState.IDLE);
            fetchComments();
        }
    };

    const handleDeleteComment = async (commentId: string) => {
        setComponentState(ComponentState.SAVING);
        try {
            await conversationClient.deleteConversationComment(viewId, rowId, commentId, reportSheetId);
        } catch {
            setComponentState(ComponentState.IDLE);
            dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.GENERIC_FATAL_ERROR)));
        } finally {
            setComponentState(ComponentState.IDLE);
            fetchComments();
        }
    };

    const handleDeleteCommentThread = async (commentId: string) => {
        setComponentState(ComponentState.SAVING);
        try {
            await conversationClient.deleteConversationComment(viewId, rowId, commentId, reportSheetId, true);
        } catch {
            setComponentState(ComponentState.IDLE);
            dispatch(AppActions.Actions.setAppStageError(new Error(languageElements.GENERIC_FATAL_ERROR)));
        } finally {
            setComponentState(ComponentState.IDLE);
            fetchComments();
        }
    };

    const getUnsavedItemsForParent = (parentCommentId: string) => {
        return unsavedReplyOrComment.filter((item) => item.parentCommentId === parentCommentId);
    };

    const getTextOfUnsavedComment = (commentId: string, parentCommentId: string) => {
        return unsavedReplyOrComment.find((item) => item.commentId === commentId && item.parentCommentId === parentCommentId)?.text || '';
    };

    // Render components when the tab is active. We still want the component to execute the code above this point to determine the total number of
    // comments to show in the tab title, just not render anything.
    if (!activeTab) {
        return null;
    }

    const style = {};

    return (
        <>
            {componentState === ComponentState.LOADING && <LoadingData />}
            {componentState === ComponentState.SAVING && (
                <ModalWrapper isModalOpen={true} onClose={() => {}} hideCloseButton={true} focusTrap={false}>
                    <Spinner label={languageElements.SPINNER_SAVING_LABEL} color={Color.BLUE} size={Size.MEDIUM} />
                </ModalWrapper>
            )}
            {(componentState === ComponentState.IDLE || componentState === ComponentState.SAVING) && (
                <div className="comments-panel" style={style}>
                    <>
                        <div className="comments" style={style}>
                            {!isEmpty(comments) &&
                                comments.map((comment) => {
                                    const unsavedReplies = getUnsavedItemsForParent(comment.id);
                                    return (
                                        <Comment
                                            key={comment.id}
                                            viewId={viewId}
                                            rowId={rowId}
                                            comment={comment}
                                            unsavedValue={unsavedReplies}
                                            reportSheetId={reportSheetId}
                                            currentUser={formattedCurrentUser}
                                            newCommentsAllowed={addComments}
                                            onSave={(unsavedComment: UnsavedComment) => handleSave(unsavedComment)}
                                            onClickDeleteComment={(commentId: string) => handleDeleteComment(commentId)}
                                            onClickDeleteCommentThread={(commentId: string) => handleDeleteCommentThread(commentId)}
                                            onChangeUnsavedReplyOrComment={(unsavedComment: UnsavedComment) =>
                                                handleChangeUnsavedReplyOrComment(unsavedComment)
                                            }
                                        />
                                    );
                                })}
                            {isEmpty(comments) && <div className="comments empty">{languageElements.DETAIL_COMMENTS_EMPTY}</div>}
                            <div style={{ float: 'left', clear: 'both' }} />
                        </div>

                        {addComments && (
                            <CommentInput
                                className={'add-comment-wrap comment-wrap'}
                                viewId={viewId}
                                rowId={rowId}
                                reportSheetId={reportSheetId}
                                currentUser={formattedCurrentUser}
                                commentId={'0'}
                                parentCommentId={'0'}
                                value={getTextOfUnsavedComment('0', '0')}
                                onSave={(unsavedItem: UnsavedComment) => handleSave(unsavedItem)}
                                onChangeUnsavedReplyOrComment={(unsavedItem: UnsavedComment) => handleChangeUnsavedReplyOrComment(unsavedItem)}
                                isEditing={false}
                            />
                        )}
                    </>

                    <Modal isOpen={showModal} onCloseRequested={handleDiscardUnsavedChanges}>
                        <UnsavedChangesModalContent
                            onCloseModalDetailsPanel={handleCloseModal}
                            onCancel={handleDiscardUnsavedChanges}
                            onSave={handleSaveAllUnsavedChanges}
                        />
                    </Modal>

                    {!showModal && isDirty && <Prompt message={languageElements.ADMIN_PANEL_PROMPT_UNSAVED_CHANGES_MESSAGE} />}
                </div>
            )}
        </>
    );
};

export default Conversation;
